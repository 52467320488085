<script setup lang="ts">
import { useIndexStore } from '@/stores/index'

//Intitial
const index = useIndexStore()

//Mounted
onMounted(() => {
  setTimeout(() => {
    index.$patch((state) => {
      state.showDialog = false
      state.firstLogin = true
      state.statusCode = 200
      state.msg = ''
    })
  }, 1500);
});
</script>

<template>
  <div>
    <v-dialog
      v-model="index.ShowDialog"
      height="187"
      class="!min-w-[300] max-w-[442px]"
    >
      <v-card height="187" class="!rounded-[14px]">
        <v-card-text class="flex flex-col justify-center !p-6">
          <div class="flex justify-center">
            <SvgoSuccess v-if="index.StatusCode < 400 && index.ShowDialog" class="!w-[82px] !h-[82px]" filled/>
            <SvgoNotFound v-else-if="index.StatusCode > 400 && index.ShowDialog" class="!w-[106px] !h-[60px]" filled/>
          </div>
          <div class="text-2xl pt-3 text-center">{{index.Msg}}</div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped></style>
